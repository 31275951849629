import axios from "@/utils/request";

export function getStaff(data) {
  return axios({
    url: "/staffs",
    method: "get",
    params: data,
  });
}

export function editStaff(id, data) {
  return axios({
    url: `/staffs/${id}/passwords`,
    method: "put",
    data,
  });
}

export function readStaff(id) {
  return axios({
    url: `/staffs/${id}`,
    method: "get",
  });
}

export function addStaff(data) {
  return axios({
    url: "/staffs",
    method: "post",
    data,
  });
}
